import {motion} from 'framer-motion';
import {type HTMLAttributes, useEffect, useState} from 'react';
import {
  type Duration,
  type DurationUnit,
  intervalToDuration,
  isBefore,
} from 'date-fns';
import {cn} from '~/utils';

type CountdownProps = {
  endDate?: Date;
} & HTMLAttributes<HTMLDivElement>;

export const Countdown = ({
  endDate = new Date(),
  className,
  children,
}: CountdownProps) => {
  const [duration, setDuration] = useState<Duration>(() =>
    intervalToDuration({start: new Date(), end: endDate})
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const duration = intervalToDuration({start: now, end: endDate});

      if (isBefore(endDate, now)) {
        clearInterval(interval);
      } else {
        setDuration(duration);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  const unitsToDisplay: DurationUnit[] = [
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
  ];

  return (
    <div className={cn(className)}>
      <div className="mb-4 flex flex-wrap items-baseline gap-x-3 md:gap-x-8">
        {unitsToDisplay.map(unit => {
          const value = duration[unit];
          return (
            <div className="flex flex-col items-center" key={unit}>
              <div className="relative flex items-baseline gap-1 text-xl tabular-nums md:flex-col md:gap-0 md:text-3xl">
                <div className="font-bold opacity-0">{formatNumber(value)}</div>
                <motion.div
                  key={formatNumber(value)}
                  animate={{
                    scale: 1,
                    transition: {
                      ease: 'easeOut',
                      duration: 0.3,
                    },
                  }}
                  initial={{scale: 1.5}}
                  className={cn(
                    'absolute top-0 font-bold lining-nums text-foreground md:right-0 md:text-right'
                  )}
                >
                  {formatNumber(value)}
                </motion.div>
              </div>
              <div className="text-xs md:text-base">{unit}</div>
            </div>
          );
        })}
      </div>
      {children}
    </div>
  );
};

const formatNumber = (number: number | undefined) => {
  return (number ?? 0)?.toLocaleString('en-CH', {
    minimumIntegerDigits: 2,
  });
};
