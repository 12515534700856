export default function Banner2024() {
  return (
    <div className="relative">
      <img
        className="block max-w-full"
        src="/images/KEYART_ASSETS/BG_Hills.png"
        alt=""
      />
      <img
        className="absolute bottom-[7%] left-[38%] w-[4%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Gift_orange.png"
        alt=""
      />
      <img
        className="absolute bottom-[7%] left-[58%] w-[6%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Gift_bleu.png"
        alt=""
      />
      <img
        className="absolute bottom-[20%] left-[1%] w-[5%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Gift_violet.png"
        alt=""
      />
      <img
        className="absolute bottom-[16%] left-[3%] w-[5%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Gift_jaune.png"
        alt=""
      />
      <img
        className="absolute bottom-[41%] right-[34%] w-[6%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Gift_jaune.png"
        alt=""
      />
      <img
        className="absolute right-[3%] top-[7%] w-1/4 object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Char_T-Rex.png"
        alt=""
      />
      <img
        className="absolute bottom-[24%] right-[2%] w-[5%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Gift_orange.png"
        alt=""
      />
      <img
        className="absolute left-0 top-[10%] w-[30%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Char_ElephantAndSkull.png"
        alt=""
      />
      <img
        className="absolute left-[7%] top-[40%] w-[15%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/Char_MrLunettes.png"
        alt=""
      />
      <img
        className="absolute left-[24%] top-[40%] w-[12%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/MrCrabBomb.png"
        alt=""
      />
      <img
        className="absolute bottom-[7%] right-[5%] w-[14%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/MrComputer.png"
        alt=""
      />
      <img
        className="absolute bottom-[7%] right-[22%] w-[16%] object-contain hover:animate-shake"
        src="/images/KEYART_ASSETS/MmeGun.png"
        alt=""
      />
      <img
        className="pointer-events-none absolute bottom-[7%] right-[35%] h-[95%] animate-spin object-contain"
        style={{animationDuration: '120s'}}
        src="/images/EGJ2024_Logo_Big_BG.png"
        alt=""
      />
      <img
        className="pointer-events-none absolute bottom-[7%] right-[35%] h-[95%] animate-spin object-contain duration-30000"
        style={{animationDirection: 'reverse'}}
        src="/images/EGJ2024_Logo_Big_TXT.png"
        alt=""
      />
    </div>
  );
}
